import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Drawer, Form, Input, notification, Select } from 'antd';
import axios from 'axios';
import { SERVER_URL } from '../../config';

const user = JSON.parse(sessionStorage.getItem('user'));

const tailLayout = {
  wrapperCol: { span: 24 },
};

export const EditUser = ({ getUsers, openModal, setOpenModal, userData, setUserData }) => {
  const [form] = Form.useForm();
  const [checked, setChecked] = useState();
  const [semesters, setSemesters] = useState([]);

  const getSemesters = async () => {
    try {
      const { data } = await axios.get(`${SERVER_URL}/semesters`);
      setSemesters(data);
    } catch (err) {
      notification.warn({
        message: err.response.data.message,
        duration: 2.5,
        placement: 'bottomRight',
      });
      console.log(err.message);
    }
  };
  const handlePasswordReset = async () => {
    if (!userData.email) {
      notification.warn({
        message: 'E-Mail-Adresse fehlt.',
        duration: 2.5,
        placement: 'bottomRight',
      });
      return;
    }

    try {
      const { data } = await axios.post(`${SERVER_URL}/password-reset-dashboard`, { email: userData.email });

      notification.success({
        message: data.status || 'Passwort-Zurücksetzen-Link gesendet.',
        duration: 2.5,
        placement: 'bottomRight',
      });
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Fehler beim Senden des Passwort-Zurücksetzen-Links.';
      notification.error({
        message: errorMessage,
        duration: 2.5,
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    getSemesters();
  }, []);

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        firstName: userData.firstName ? userData.firstName : '',
        lastName: userData.lastName ? userData.lastName : '',
        code: userData.code ? userData.code : '',
        role: userData.role ? userData.role : '',
        status: userData.status ? userData.status : '',
        semester: userData.semester?._id ? userData.semester?._id : '',
        email: userData.email ? userData.email : '',
      });
      setChecked(userData.newsletter);
    }
  }, [userData, form]);

  const updateUser = async (values) => {
    try {
      const { data } = await axios.put(
        `${SERVER_URL}/users/${userData._id}`,
        { ...values, newsletter: checked },
        { withCredentials: false, headers: { Authorization: `Bearer ${user.token}` } },
      );
      notification.success({
        message: data.message,
        placement: 'bottomRight',
        duration: 2.5,
      });
      getUsers();
      setOpenModal(false);
    } catch (err) {
      if (err.response.data.message) {
        notification.warn({
          message: err.response.data.message,
          duration: 2.5,
          placement: 'bottomRight',
        });
      }
      console.log(err.message);
    } finally {
      form.resetFields();
      setUserData(undefined);
    }
  };

  const closeModal = () => {
    form.resetFields();
    setOpenModal(false);
    setUserData(undefined);
  };

  return (
    <Drawer title='Benutzer ändern' placement='right' onClose={closeModal} open={openModal} width={420}>
      <div className='dashboard user-form'>
        <div className='panel panel-body'>
          <Form initialValues={userData} form={form} onFinish={updateUser}>
            <Form.Item
              label='Vorname'
              name='firstName'
              rules={[
                {
                  required: true,
                  message: 'Pflichtfeld!',
                },
              ]}
            >
              <Input placeholder='Vorname' />
            </Form.Item>
            <Form.Item
              label='Nachname'
              name='lastName'
              rules={[
                {
                  required: true,
                  message: 'Pflichtfeld!',
                },
              ]}
            >
              <Input placeholder='Nachname' />
            </Form.Item>
            <Form.Item
              label='Matnr. (K...) AK-Nr. (AK...)'
              name='code'
              rules={[
                {
                  required: true,
                  message: 'Pflichtfeld!',
                },
              ]}
            >
              <Input placeholder='Matnr. (K...) AK-Nr. (AK...)' />
            </Form.Item>

            <Form.Item
              label='E-Mail-Addresse'
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Pflichtfeld!',
                },
              ]}
            >
              <Input placeholder='E-Mail-Addresse' />
            </Form.Item>

            <Form.Item
              name='role'
              rules={[
                {
                  required: true,
                  message: 'Pflichtfeld!',
                },
              ]}
            >
              <Select getPopupContainer={(trigger) => trigger.parentNode} placeholder='Rolle'>
                <Select.Option value='admin'>admin</Select.Option>
                <Select.Option value='student'>student</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label='Semester'
              name='semester'
              rules={[
                {
                  required: true,
                  message: 'Pflichtfeld!',
                },
              ]}
            >
              <Select getPopupContainer={(trigger) => trigger.parentNode} placeholder='Semester'>
                {semesters.map((semester) => (
                  <Select.Option value={semester._id}>{semester.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label='Mail-Benachrichtigungen senden' name='newsletter'>
              <Checkbox onClick={() => setChecked((prevState) => !prevState)} checked={checked} />
            </Form.Item>

            <Form.Item
              label='Status'
              name='status'
              rules={[
                {
                  required: true,
                  message: 'Pflichtfeld!',
                },
              ]}
            >
              <Select getPopupContainer={(trigger) => trigger.parentNode}>
                <Select.Option value='Aktiv'>Aktiv</Select.Option>
                <Select.Option value='Deaktiviert'>Deaktiviert</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <div className='ant-btn-placeholder'>
                <Button type='primary' style={{ width: '100%' }} onClick={handlePasswordReset}>
                  Passwort zurücksetzen
                </Button>
              </div>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <div className='ant-btn-placeholder'>
                <Button type='primary' htmlType='submit' style={{ marginBottom: '20px', width: '100%' }}>
                  Daten aktualisieren
                </Button>
                <Button type='secondary' onClick={closeModal}>
                  Schließen
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Drawer>
  );
};
